$(document).ready(function() {

  //slidbar
  $.slidebars({
    disableOver: 1279
  });
  //banner
  $('.bxslider_banner').bxSlider({
    speed: 1000,
    auto: true,
    mode: 'fade'
  });
  $('.bxslider_teacher').bxSlider({
    // mode: 'vertical',
    // slideMargin: 5,
    // infiniteLoop:false
    speed: 1000,
    auto: true,
    mode: 'fade'
  });
  //slidbar
  $('.second-list-btn').on('click', function() {
    $(this).parent().siblings().find('.second-list-btn').siblings('.second-list').slideUp().promise().done(function() {
      $(this).siblings().removeClass('active');
    });
    $(this).siblings('.second-list').slideDown().promise().done(function() {
      $(this).siblings().addClass('active');
    });
  });

});

//
$('a.deletce-btn').click(function() {
  $('tr.deletce-block').slideUp();
});

//
// $(function() {
//   var len = 120; // 超過120個字以"..."取代
//   $('.JQellipsis').each(function(i) {
//     if ($(this).text().length > len) {
//       $(this).attr('title', $(this).text());
//       var text = $(this).text().substring(0, len - 1) + '...';
//       $(this).text(text);
//     }
//   });
// });

$(function() {
  var len = 110; // 超過120個字以"..."取代
  $('.book-text-t').each(function(i) {
    if ($(this).text().length > len) {
      $(this).attr('title', $(this).text());
      var text = $(this).text().substring(0, len - 1) + '...';
      $(this).text(text);
    }
  });
});

//product-d
$(function() {
  $('.qa').click(function() {
    var _this = $(this).attr('data-href');
    if ($(_this).css('display') == 'none') {
      $('.trclass').slideUp();
      $('.open').css('color', '#555555');
      $('.thisicon').attr('src', 'images/sprite/open.png');
      $(_this).slideDown();
      $(this).find('IMG.thisicon').attr('src', 'images/sprite/close.png');
      $(this).find('.open').css('color', '#0c60a3');
    } else {
      $(_this).slideUp();
      $(this).find('IMG.thisicon').attr('src', 'images/sprite/open.png');
      $('.open').css('color', '#0c60a3');
    }
    return false;
  });
  $('.qa-message').click(function() {
    var _this = $(this).attr('data-href');
    if ($(_this).css('display') == 'none') {
      $('.trclass').slideUp();
      $('.open').css('color', '#555555');
      $('.thisicon').attr('src', 'images/sprite/open.png');
      $(_this).slideDown();
      $(this).find('IMG.thisicon').attr('src', 'images/sprite/close.png');
      $(this).find('.open').css('color', '#0c60a3');
    } else {
      $(_this).slideUp();
      $(this).find('IMG.thisicon').attr('src', 'images/sprite/open.png');
      $('.open').css('color', '#0c60a3');
    }
    return false;
  });
  // //預設第一筆
  // $('#tr1').slideDown();
  // $('.thisone_img').attr('src', 'images/sprite/close.png');
  // $('.thisone_text').css('color', '#0c60a3');

});
//
$('.popup-gallery').magnificPopup({
  delegate: 'a',
  type: 'image',
  tLoading: 'Loading image #%curr%...',
  mainClass: 'mfp-img-mobile',
  gallery: {
    enabled: true,
    navigateByImgClick: true,
    preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
  },
  image: {
    tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
    titleSrc: function(item) {
      return item.el.attr('title') + '<br/>'+item.el.attr('small');
    }
  }
});

//
// Inline popups
$('#inline-popups').magnificPopup({
  delegate: 'a',
  removalDelay: 500, //delay removal by X to allow out-animation
  callbacks: {
    beforeOpen: function() {
      this.st.mainClass = this.st.el.attr('data-effect');
    }
  },
  midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
});


// Image popups
$('#image-popups').magnificPopup({
  delegate: 'a',
  type: 'image',
  removalDelay: 500, //delay removal by X to allow out-animation
  callbacks: {
    beforeOpen: function() {
      // just a hack that adds mfp-anim class to markup
      this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
      this.st.mainClass = this.st.el.attr('data-effect');
    }
  },
  closeOnContentClick: true,
  midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
});


$('.open-popup-link').magnificPopup({
  type: 'ajax',
  overflowY: 'scroll' // as we know that popup content is tall we set scroll overflow by default to avoid jump
});


// Hinge effect popup
$('a.hinge').magnificPopup({
  mainClass: 'mfp-with-fade',
  removalDelay: 1000, //delay removal by X to allow out-animation
  callbacks: {
    beforeClose: function() {
      this.content.addClass('hinge');
    },
    close: function() {
      this.content.removeClass('hinge');
    }
  },
  midClick: true
});

//
//login-in-popup
$('.popup-with-zoom-anim').magnificPopup({
  type: 'inline',

  fixedContentPos: false,
  fixedBgPos: true,

  overflowY: 'auto',

  closeBtnInside: true,
  preloader: false,

  midClick: true,
  removalDelay: 300,
  mainClass: 'my-mfp-zoom-in',

  callbacks: {
    open: function() {
      $('a.error').click(function() {
        $(this).parents('.submin-btn').siblings('.login-input').find('.error-text').slideDown();
      });
    }
  }
});
//
$('.sign-up-popup').magnificPopup({
  type: 'inline',

  fixedContentPos: false,
  fixedBgPos: true,

  overflowY: 'auto',

  closeBtnInside: true,
  preloader: false,

  midClick: true,
  removalDelay: 300,
  mainClass: 'my-mfp-zoom-in',

  callbacks: {
    open: function() {
      $('a.error').click(function() {
        $(this).parents('.submin-btn').siblings('.login-input').find('.error-text').slideDown();
      });
    }
  }
});
//
$('#inline-popups').magnificPopup({
  delegate: 'a',
  removalDelay: 500, //delay removal by X to allow out-animation
  callbacks: {
    beforeOpen: function() {
       this.st.mainClass = this.st.el.attr('data-effect');
    }
  },
  midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
});
//top
$(function(){
	$(window).scroll(function() {
		if($(window).scrollTop() >= 100){
			$('.actGotop').fadeIn(300);
		}else{
			$('.actGotop').fadeOut(300);
		}
	});
	$('.actGotop').click(function(){
	$('html,body').animate({scrollTop: '0px'}, 800);});
});

//history
$(function(){
    var len = 30; // 超過65個字以"..."取代
    $(".history a").each(function(i){
        if($(this).text().length>len){
            $(this).attr("title",$(this).text());
            var text=$(this).text().substring(0,len-1)+"...";
            $(this).text(text);
        }
    });
});


//
$('.teacher-people').on('change', 'select', function() {
    $('.people-detail-block').slideDown();
  });

//select

  var urlmenu = document.getElementById('menu1');
  urlmenu.onchange = function() {
    window.open(this.options[this.selectedIndex].value);
  };


  var urlmenu = document.getElementById('menu2');
  urlmenu.onchange = function() {
    window.open(this.options[this.selectedIndex].value);
  };
